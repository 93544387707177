import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import Footer from './footer';
import Header from './header';

import UnderMaintenance from './UnderMaintenance';

import * as ga from '../libs/ga';

const App = ({ setting, children }) => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageView(url, setting.ga_id)
    }
    
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{setting.site_title}</title>
        <meta name="description" content={setting.site_description} />
        <meta name="keyword" content={setting.site_keyword} />
        <link rel="canonical" href={`${process.env.appUrl}/${router.locale}${router.asPath}`} />
        <link rel="shortcut icon" href={setting.favicon} />
        <meta property="fb:app_id" content={setting.fb_id} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={setting.soc_title} />
        <meta property="og:description" content={setting.soc_description} />
        <meta property="og:image" content={setting.soc_image} />
        <meta property="og:url" content={`${process.env.appUrl}/${router.locale}${router.asPath}`} />
        <meta property="og:site_name" content={setting.site_name} />
        <meta name="twitter:title" content={setting.soc_title} />
        <meta name="twitter:description" content={setting.soc_description} />
        <meta name="twitter:image" content={setting.soc_image} />
        <meta name="twitter:image:alt" content={setting.soc_title} />
        <meta name="twitter:card" content="summary_large_image" />
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${setting.ga_id}`} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${setting.ga_id}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Head>
      {
        (setting.maintenance) ?
          <UnderMaintenance />
          :
          <>
            <Header logo={setting.logo}></Header>
            <main className="page-content-container">{children}</main>
            <Footer setting={setting}></Footer>
          </>
      }
    </>
  );
}

export default App;