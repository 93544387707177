import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import cn from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import { useResizeDetector } from 'react-resize-detector'

import { getUploadImageBase64 } from '../helpers/utils'

import style from '../styles/components/header.module.scss'

const Header = ({ logo }) => {
  const router = useRouter()
  
  const [languagesSelectorStatus, setLanguagesSelectorStatus] = useState(false)

  const [websiteSearchStatus, setWebsiteSearchStatus] = useState(false)
  const [websiteSearchValue, setWebsiteSearchValue] = useState('')

  const [logoImage, setLogoImage] = useState('')

  const [mobileMenuStatus, setMobileMenuStatus] = useState(false)

  useEffect(() => {
    getLogoImageData();
  }, [router])

  const getLogoImageData = async () => {
    const logoImageData = await getUploadImageBase64(`${process.env.appUrl}`, logo);
    await setLogoImage(logoImageData);
  }

  const onResize = useCallback(() => {
    resetState()
  }, [])

  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: 1000,
    onResize
  })

  const handleLanguageChange = (language) => {
    setLanguagesSelectorStatus(false)
    router.push(router.asPath, router.asPath, { locale: language } )
  }

  const handleWebsiteSearch = () => {
    var body = document.querySelector('body').classList
    if(websiteSearchStatus) {
      body.remove('website-search-active')
      setWebsiteSearchStatus(false)
    } else {
      body.add('website-search-active')
      setWebsiteSearchStatus(true)
    }
  }

  const resetState = () => {
    // clear open language selector state
    setLanguagesSelectorStatus(false)
    
    // clear open website search state
    document.querySelector('body').classList.remove('website-search-active')
    setWebsiteSearchStatus(false)

    // clear open mobile header menu state
    setMobileMenuStatus(false)
  }

  return (
    <header ref={ref} className={style.header}>
      <div className={style.header_content}>
        <div className={style.main_header_content}>
          <div className={`${style.header_content_container} container align-items-center justified-content-space-between`}>

            <div className={`${style.logo_container}`}>
              <a className={`${style.hamburger_menu_button} align-items-center ${ cn({ [ style.mb_menu_active ]: mobileMenuStatus === true }) }`} onClick={() => { setMobileMenuStatus(!mobileMenuStatus) }}>
                <span className={`${style.hamburger_menu_btn} img-responsive`}><img src="/images/share/icon-hamburger-menu.svg" className='full-w' alt="Header menu button" /></span>
                <span className={`${style.close_menu_btn} img-responsive`}><img src="/images/share/icon-cross.svg" className='full-w' alt="close button" /></span>
              </a>

              <div className={`${style.logo_block}`}>
                <Link href="/"><a className="img-block img-responsive"><img src={logoImage} layout="fill" alt="MAYA - lifestyle shopping center" /></a></Link>
              </div>

              <Link href="#">
                <a className={`${style.shopping_mb_button} align-items-center justified-content-center`}>
                  <span className={`${style.shop_now_icon} img-responsive d-block`}><Image src="/images/share/icon-shopping-bag.png" layout="fill" alt="shopping bag" /></span>
                </a>
              </Link>
            </div>

            <nav className={`${style.menu_container} ${ cn({ [ style.mb_menu_container_active ]: mobileMenuStatus === true }) }`}>
              <ul>
                <li><Link href="/places/store"><a className={style.menu_item}>Place</a></Link></li>
                <li><Link href="/promotion"><a className={style.menu_item}>Promotions</a></Link></li>
                <li><Link href="/news-article"><a className={style.menu_item}>News &amp; Articles</a></Link></li>
                <li><Link href="/activities"><a className={style.menu_item}>Activities</a></Link></li>
                <li><Link href="/about-us"><a className={style.menu_item}>About us</a></Link></li>
                <li><Link href="/contact-us"><a className={style.menu_item}>Contact us</a></Link></li>
              </ul>
            </nav>
            <div className={`${style.search_lang_container} d-flex`}>
              <a className={`${style.search_button} d-none img-block`} onClick={() => { handleWebsiteSearch() }}><img src="/images/share/icon-search.svg" className="full-w" alt="website search" /></a>

              <div className={style.language_selector_block}>
                <a className={`${style.language_selector} d-flex align-items-center`} onClick={() => { setLanguagesSelectorStatus(!languagesSelectorStatus) }}>
                  <span className={`${style.language_value} d-block`}>{ router.locale.toUpperCase() }</span>
                  <span className="img-responsive d-flex"><img src="/images/share/icon-arrow-down.svg" className='full-w' alt="down" /></span>
                </a>
                <div className={`${style.language_option_block} ${ cn({ [ style.language_option_block_active ]: languagesSelectorStatus === true }) }`}>
                  <a key="language-th" className={`${ cn({ [ style.language_option_selected ]: router.locale === 'th' }) }`} onClick={() => { handleLanguageChange('th') }}>TH</a>
                  <a key="language-en" className={`${ cn({ [ style.language_option_selected ]: router.locale === 'en' }) }`} onClick={() => { handleLanguageChange('en') }}>EN</a>
                  <a key="language-ch" className={`${ cn({ [ style.language_option_selected ]: router.locale === 'ch' }) }`} onClick={() => { handleLanguageChange('ch') }}>CH</a>
                </div>
              </div>

              <div className={style.shopping_button_block}></div>
              <Link href="#">
                <a className={`${style.shop_now_button} d-flex align-items-center`} title="Coming soon">
                  <span className={`${style.shop_now_icon} img-responsive d-block`}><Image src="/images/share/icon-shopping-bag.png" layout="fill" alt="coming soon" /></span>
                  <span className="d-block">COMING SOON</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${style.website_search_container} ${ cn({ [ style.website_search_container_active ]: websiteSearchStatus === true }) } ${ cn({ [ style.mb_website_search_container_active ]: mobileMenuStatus === true }) }`}>
          <div className="container">
            <div className={`${style.search_form_control_block} d-flex justified-content-end`}>
              <div className="d-none">
                <input type="text" name="website_search" id="website_search" className={`${style.search_form_control} form-control`} value={websiteSearchValue} onChange={(e) => setWebsiteSearchValue(e.target.value)} placeholder="ค้นหา" />
                <button className={`${style.website_search_button} btn primary-btn`}>
                  <span className={`${style.website_search_icon} img-responsive d-flex`}><img src="/images/share/icon-search-white.svg" className='full-w' alt="website search" /></span>
                </button>
              </div>

              <div className={`${style.language_selector_block}`}>
                <a className={`${style.language_selector} d-flex align-items-center`} onClick={() => { setLanguagesSelectorStatus(!languagesSelectorStatus) }}>
                  <span className={`${style.language_value} d-block`}>{ router.locale.toUpperCase() }</span>
                  <span className={`${style.language_icon} img-responsive d-flex`}><img src="/images/share/icon-arrow-down.svg" className='full-w' alt="down" /></span>
                </a>
                <div className={`${style.language_option_block} ${ cn({ [ style.language_option_block_active ]: languagesSelectorStatus === true }) }`}>
                  <a key="language-th" className={`${ cn({ [ style.language_option_selected ]: router.locale === 'th' }) }`} onClick={() => { handleLanguageChange('th') }}>TH</a>
                  <a key="language-en" className={`${ cn({ [ style.language_option_selected ]: router.locale === 'en' }) }`} onClick={() => { handleLanguageChange('en') }}>EN</a>
                  <a key="language-ch" className={`${ cn({ [ style.language_option_selected ]: router.locale === 'ch' }) }`} onClick={() => { handleLanguageChange('ch') }}>CH</a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = state => {
  return { language: state.language.value }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
