import Link from 'next/link';

import style from '../styles/components/footer.module.scss';

function Footer({ setting }) {
  return (
    <footer className={style.footer}>
      <div className={`${style.footer_content} container`}>
        <div className={style.footer_info_container}>
          <p>© 2021 MAYA development Co., Ltd.. All Rights Reserved</p>
          <p>Terms &amp; Conditions | Privacy Policy </p>
        </div>
        <div className={style.footer_social_container}>
          {
            setting.ig_link && 
            <Link href={setting.ig_link}>
              <a className={`${style.footer_social_icon} img-link`} target="_blank"><img src="/images/share/icon-white-ig.svg" alt="INSTAGRAM" /></a>
            </Link>
          }
          {
            setting.tw_link && 
            <Link href={setting.tw_link}>
            <a className={`${style.footer_social_icon} img-link`} target="_blank"><img src="/images/share/icon-white-tw.svg" alt="TWITTER" /></a>
          </Link>
          }
          {
            setting.fb_link && 
            <Link href={setting.fb_link}>
              <a className={`${style.footer_social_icon} img-link`} target="_blank"><img src="/images/share/icon-white-fb.svg" alt="FACEBOOK" /></a>
            </Link>
          }
          {
            setting.ta_link && 
            <Link href={setting.ta_link}>
              <a className={`${style.footer_social_icon} img-link`} target="_blank"><img src="/images/share/icon-white-trip-adviser.svg" alt="TRIP ADVISER" /></a>
            </Link>
          }
        </div>
      </div>
    </footer>
  );
}

export default Footer;