import { useMemo } from 'react';
import axios from 'axios';
const cheerio = require('cheerio');

export const debounce = (fn, ms) => {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}

export const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

export const isUndefinedOrNull = element => {
  return (typeof(some_variable) !== "undefined" && some_variable !== null);
}

export const contentType = (type) => {
  switch (type) {
    case 1:
      return 'Maya';
    case 2:
      return 'Store';
    case 3:
      return 'Other';
    default:
      return 'Unknown';
  }
}

export const DOTS = '...';

export const usePagination = ({ totalPageCount, siblingCount = 1, currentPage }) => {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min( currentPage + siblingCount, totalPageCount );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;
    
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [currentPage, siblingCount, totalPageCount]);
  
  return paginationRange;
};

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const getUploadImage = (imgUrl) => {
  if(!imgUrl) {
    return '';
  }
  
  const imgArr = imgUrl.split("/");
  const img = `/uploads/${imgArr[imgArr.length - 1]}`;
  return img;
}

export const getUploadImageBase64 = async (apiUrl, imgUrl) => {
  if(!imgUrl) {
    return '';
  }
  
  const imgArr = imgUrl.split("/");
  const imgRes = await axios.get(`${apiUrl}/api/image-upload`, {
    params: {
      img: imgArr[imgArr.length - 1]
    }
  });

  if(imgRes.data.status == 'failed') {
    return '/images/share/default-img.png';  
  }
  
  return imgRes.data.img;
}

export const getWysiwygUploadImage = async (apiUrl, content) => {
  const $ = cheerio.load(content);
  for (const i in $('img')) {
    if($('img')[i].attribs) {
      $('img')[i].attribs.src = await getUploadImageBase64(apiUrl, $('img')[i].attribs.src)
    }
  }
  
  return $('body').html()
}

export const getImageUrlApi = (apiUrl, imgUrl) => {
  if(!imgUrl) {
    return '';
  }
  
  const imgArr = imgUrl.split("/");
  const imgLink = `${apiUrl}/api/image/${imgArr[imgArr.length - 1]}`;
  return imgLink;
}
